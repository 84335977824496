// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-mdx": () => import("./../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-templates-author-js": () => import("./../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-category-js": () => import("./../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}


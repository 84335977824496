import React from 'react';
import { IdentityContextProvider } from 'react-netlify-identity';
import 'react-netlify-identity-widget/styles.css';

import './src/utils/nightowl.css';
import 'prismjs/plugins/match-braces/prism-match-braces.css';
import './src/utils/prism-line-numbers.css';
import './src/utils/globals.css';

export const wrapRootElement = ({ element }) => {
  return <IdentityContextProvider url="https://sprinklr.wiki">{element}</IdentityContextProvider>;
};

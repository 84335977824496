module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-172740311-1","head":true,"anonymize":true,"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sprinklr Wiki","short_name":"Wiki","start_url":"/","display":"standalone","icon":"src/images/Sprinklr.png","lang":"en","theme_color":"#fff","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8d6cadc767ff4a3a4901a18e2a41b317"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/Users/chicho17/wiki/node_modules/gatsby-remark-images","id":"9e21bfea-9d49-5f1d-8455-3402b90893f9","name":"gatsby-remark-images","version":"3.3.10","pluginOptions":{"plugins":[],"wrapperStyle":"width:100%;max-width:100%","withWebp":true,"maxWidth":1200,"quality":90,"linkImagesToOriginal":false},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{"default":"/Users/chicho17/wiki/src/components/layout-wrapper/layout-home.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"wrapperStyle":"width:100%;max-width:100%","withWebp":true,"maxWidth":1200,"quality":90,"linkImagesToOriginal":false}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"wrapperStyle":"width:100%;max-width:100%","withWebp":true,"maxWidth":1200,"quality":90,"linkImagesToOriginal":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
